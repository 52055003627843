import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useDesignSystemInit from '../../hooks/useDesignSystemInit';
import useMarketingFilters from '../../hooks/useMarketingFilters';

const query = gql`
  query Content($espotId: String!, $singleEspot: Boolean, $storePreview: String) {
    content(espotId: $espotId, singleEspot: $singleEspot, storePreview: $storePreview) {
      espotId
      htmlContent
      timestamp
    }
  }
`;

export default function SingleAemEspot({
  espotId,
  content = null,
  timestamp,
  removeDuplicateAccordionIds = false,
  storePreview = 'false',
}) {
  const { data } = useQuery(query, {
    variables: { espotId, singleEspot: true, storePreview },
    skip: !!content,
  });
  const [duplicateAccordionIdsRemoved, setDuplicatedAccordionIdsRemoved] = useState(false);
  const htmlContent = content || data?.content?.htmlContent;
  const cacheTimestamp = timestamp || data?.content?.timestamp;
  const espotRef = useRef(null);

  /**
   * This is a temporary workaround until the content team removes problematic and unused accordion
   * panel elements with duplicate IDs from desktop navigation eSpots.
   * See https://git.homeoffice.anfcorp.com/ecommerce/catalog-mfe/-/merge_requests/1447#note_1087855
   */
  useEffect(() => {
    if (espotRef.current && htmlContent && removeDuplicateAccordionIds) {
      // Get all IDs from accordion panel elements in this eSpot
      const accordionPanelElements = espotRef.current.querySelectorAll('.js-accordion-panel[id]');
      const accordionPanelIds = [...accordionPanelElements]
        .map((element) => element.id)
        .filter((id) => id?.trim());

      // Select all accordion panel elements with duplicate IDs outside of this eSpot
      if (accordionPanelIds.length) {
        const duplicateIdAccordionElements = [...document.querySelectorAll(accordionPanelIds.map((id) => `#${id}`).join(','))]
          .filter((element) => !espotRef.current.contains(element));

        // Remove duplicate IDs
        duplicateIdAccordionElements.forEach((element) => element.removeAttribute('id'));
      }
      setDuplicatedAccordionIdsRemoved(true);
    }
  }, [espotRef, htmlContent, removeDuplicateAccordionIds]);
  useDesignSystemInit(
    espotRef,
    [],
    !htmlContent || (removeDuplicateAccordionIds && !duplicateAccordionIdsRemoved),
  );
  useMarketingFilters(espotRef, espotId);

  const genericContentClass = htmlContent
    ? 'genericESpot js-aem-content aem-content aem-enabled aem-mfe'
    : 'genericESpot genericESpot--empty';

  return (
    <div
      ref={espotRef}
      className={genericContentClass}
      dangerouslySetInnerHTML={{ __html: htmlContent ?? '' }}
      data-cachets={cacheTimestamp}
      data-espotname={espotId}
      id={espotId}
    />
  );
}

SingleAemEspot.propTypes = {
  content: PropTypes.string,
  espotId: PropTypes.string.isRequired,
  removeDuplicateAccordionIds: PropTypes.bool,
  storePreview: PropTypes.string,
  timestamp: PropTypes.string,
};
