import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown';
import CategoryGridResultsCount from '../CategoryGridResultsCount/CategoryGridResultsCount';
import ProductGridTagGroup from '../ProductGridTagGroup/ProductGridTagGroup';
import RefineButton from '../Refine';
import SortBy from '../SortBy/SortBy';
import style from './ProductGridHeader.module.scss';

function CategoryGridHeader({
  brand,
  categoryId,
  categoryName,
  categoryTitle = '',
  categoryUrl = '',
  facet = [],
  facetData = [],
  filter = '',
  hasSelectedCategoryFilters = false,
  navParentCategoryId,
  onCheckBoxChange = () => { },
  onClearAllBtnClick = () => { },
  onFacetTagClick = () => { },
  onSortChange = () => { },
  resultsCount,
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
  storeDetails = {
    storeName: '',
    storeId: '',
  },
}) {
  const categoryResultsCount = hasSelectedCategoryFilters ? (
    <CategoryGridResultsCount
      count={resultsCount}
      hasMobileGridHeader
      hasSelectedFilters={hasSelectedCategoryFilters}
      onClearAllBtnClick={onClearAllBtnClick}
    />
  ) : null;

  return (
    <>
      {/* Mobile Version */}
      <div
        className={classNames(
          style.productGridHeader, style.productGridHeaderMobile, style.categoryGridHeader,
        )}
        data-testid="category-grid-header-mobile"
      >
        <h1 className="screen-reader-text">{categoryTitle || categoryName}</h1>
        <h2 className={style.categoryHeadingMobile}>
          <a href={categoryUrl}>{categoryName || categoryTitle}</a>
        </h2>
        <div className={style.categoryDropdownWrapperMobile} data-testid="category-dropdown-mobile">
          <CategoryDropdown currentCategoryId={categoryId} parentCategoryId={navParentCategoryId} />
        </div>
        <div className={style.filters}>
          {categoryResultsCount}
          <div className={style.filterTrack}>
            <ProductGridTagGroup
              facet={facet}
              hasMobileGridHeader
              setFacetState={onFacetTagClick}
            />
          </div>
        </div>
        <div className={style.lastColumn}>
          <RefineButton
            brand={brand}
            facet={facet}
            facetData={facetData}
            filter={filter}
            isCategoryPage
            isFacetSelected={facet?.length > 0 || !!filter}
            onCheckBoxChange={onCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onSortChange={onSortChange}
            resultsCount={resultsCount}
            selectedSort={selectedSort}
            sortData={sortData}
            storeDetails={storeDetails}
          />
        </div>
      </div>

      {/* Desktop Version */}
      <div
        className={classNames(
          style.productGridHeader, style.productGridHeaderDesktop, style.categoryGridHeader,
        )}
        data-testid="category-grid-header-desktop"
      >
        <div className={style.categoryHeadingWrapperDesktop}>
          <h1 className={`h3 ${style.categoryHeadingDesktop}`}>{categoryTitle || categoryName}</h1>
          {categoryResultsCount}
        </div>
        <div className={style.filters}>
          <div className={style.filterTrack}>
            <ProductGridTagGroup
              facet={facet}
              hasMobileGridHeader
              setFacetState={onFacetTagClick}
            />
          </div>
        </div>
        <div className={style.lastColumn}>
          {sortData.sortOptions?.length !== 0 ? (
            <SortBy onSortChange={onSortChange} selectedSort={selectedSort} sortData={sortData} />
          ) : null}
        </div>
      </div>
    </>
  );
}

CategoryGridHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string,
  categoryUrl: PropTypes.string,
  resultsCount: PropTypes.number.isRequired,
  facet: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  filter: PropTypes.string,
  hasSelectedCategoryFilters: PropTypes.bool.isRequired,
  navParentCategoryId: PropTypes.string.isRequired,
  onFacetTagClick: PropTypes.func,
  onSortChange: PropTypes.func,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onClearAllBtnClick: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};

export default CategoryGridHeader;
