import { gql } from '@apollo/client';

const espotFragment = gql`
  fragment Espot on Category {
    espotContent {
      espotId
      htmlContent
      timestamp
    }
    espotIdentifier
  }
`;

export default espotFragment;
