import PropTypes from 'prop-types';
import React from 'react';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import style from './CategoryGridResultsCount.module.scss';

const CategoryGridResultsCount = ({
  count,
  onClearAllBtnClick = () => { },
}) => {
  const handleClearAllClick = (event) => {
    onClearAllBtnClick();
    window.dispatchEvent(new CustomEvent('clearAllButtonClick', { detail: { event } }));
  };

  return (
    <div
      className={
      `${style.wrapper} ${style.wrapperWithMfeGridHeader}`
      }
      data-testid="category-grid-results-count"
    >
      <NumberOfResults className={style.count} count={count} />
      <ClearAllButton onClick={handleClearAllClick} />
    </div>
  );
};

CategoryGridResultsCount.propTypes = {
  count: PropTypes.number.isRequired,
  onClearAllBtnClick: PropTypes.func,
};

export default CategoryGridResultsCount;
