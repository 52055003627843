import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import DigitalDataProvider, {
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
} from '../DigitalDataProvider';
import style from './LargeScreenNavBar.module.scss';
import LargeScreenNavBarCategory from './LargeScreenNavBarCategory';

const getSubcategoriesForCategory = gql`
  query CategorySubcategories($categoryId: String!, $skipCache: Boolean, $storePreview: String) {
    category(categoryId: $categoryId, skipCache: $skipCache, storePreview: $storePreview) {
      brand
      brandLabel
      categoryId
      mainNavLogoData {
        tagType
        largeScreen
        largeScreen2x
        largeScreenPoster
      }
      brandLogoType
      url
      navSpecialInteractionCategories
      subCategories(cacheEmpty: false, hideCategory: false) {
        categoryId
        espotIdentifier
        isFullWidthFlyout
        name
        url
        topNavEspotContent {
          espotId
          htmlContent
          timestamp
        }
        subCategories(displayTypes: ["regular", "clearance", "featured"], expandByDisplayTypes: ["regular"]) {
          categoryId
          displayType
          name
          url
          subCategories {
            categoryId
            name
            url
          }
        }
      }
    }
  }
`;

const hideL1ClassName = 'nav-bar-item-hidden';

/*
  We'll be feeding this component a top level (aka "L0") categoryId prop,
  meaning that the subcategories that we'll be iterating through will
  be our L1 categories!
*/
const LargeScreenNavBar = ({ categoryId, storePreview }) => {
  const l1Container = useRef();
  const mainMenuLabel = useTranslatedText('mainMenuLabel', { fallback: 'Main Menu' });

  const handleWrappingListItems = () => {
    const l1List = l1Container.current;
    const l1Items = l1List?.querySelectorAll('.nav-bar-category-list-item') || null;
    if (l1Items) {
      l1Items.forEach((l1Item, l1ItemIndex) => {
        if (l1Item.offsetTop !== 0) {
          // choosing to modify classes so we can control espots l1s visibility
          l1Items[l1ItemIndex].classList.add(hideL1ClassName);
        } else {
          l1Items[l1ItemIndex].classList.remove(hideL1ClassName);
        }
      });
    }
  };

  // On mount and window resize, hide any wrapping L1 list items
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (l1Container.current) {
      const observer = new ResizeObserver(() => {
        handleWrappingListItems();
      });

      observer.observe(l1Container.current);
      l1Container.current?.style?.setProperty('overflow', 'visible');

      // Cleanup function
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const openMenuText = useTranslatedText('openMenu', { fallback: 'Open Menu' });
  const allText = useTranslatedText('all', { fallback: 'All' });

  const {
    loading, error, data, refetch,
  } = useQuery(getSubcategoriesForCategory, {
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    variables: {
      categoryId,
      storePreview,
    },
  });

  /**
   * Retry query on client-side if cache-restored server-side query data does not include
   * sub-categories. This allows us to bypass orchestration and CDN cache layers, which may
   * have cached a problematic state of this component, e.g., blank L1 links due to API error.
   */
  useEffectOnce(() => {
    if (!data?.category?.subCategories?.length) {
      refetch({
        skipCache: true,
      });
    }
  });

  const [openCategoryId, setOpenCategoryId] = useState(null);
  const isBackdropVisible = !!openCategoryId;

  if (loading || error || !data?.category) {
    return null;
  }

  const customLogo = data.category.mainNavLogoData;
  let logoContent;

  if (customLogo && customLogo.largeScreen) {
    if (customLogo.tagType === 'video') {
      logoContent = (
        <video
          className="custom-logo"
          muted
          playsInline
          poster={customLogo.largeScreenPoster}
        >
          <source data-res="1x" data-src={customLogo.largeScreen} type="video/mp4" />
          <source data-res="2x" data-src={customLogo.largeScreen2x} type="video/mp4" />
        </video>
      );
    } else {
      logoContent = <img alt={data.category.brandLabel} className="custom-logo" src={customLogo.largeScreen} />;
    }
  } else {
    logoContent = data.category.brandLogoType ? <Logo kind={data.category.brandLogoType} /> : null;
  }

  return (
    <DigitalDataProvider
      keys={[
        DD_SHOW_ANF_NAVIGATION_L3S_TEST,
      ]}
    >
      <>
        <div className="large-screen-nav-container scope-1892">
          <a aria-label={data.category.brand} className="logo-link" href={data.category.url}>
            {logoContent}
          </a>
          <nav aria-label={mainMenuLabel?.value} className="large-screen-nav-bar" data-testid="main-menu-nav">
            <ul ref={l1Container} className={style.l1container} data-level="1">
              {data.category.subCategories?.map((category) => category
                && category.categoryId && (
                  <LargeScreenNavBarCategory
                    key={category.categoryId}
                    allText={allText.value}
                    brand={data.category.brand ?? ''}
                    category={category}
                    handleWrappingListItems={handleWrappingListItems}
                    hasAemLink={
                      data
                        .category.navSpecialInteractionCategories?.includes(category.categoryId)
                        ?? false
                    }
                    openCategoryId={openCategoryId ?? ''}
                    openMenuText={openMenuText.value}
                    setOpenCategoryId={setOpenCategoryId}
                    storePreview={storePreview ?? ''}
                  />
              ))}
            </ul>
          </nav>
        </div>
        <DomNodePortal targetNodeSelector="#catalog-mfe-header-backdrop">
          <div className={classNames(
            style.backdrop,
            {
              [style.backdropVisible]: isBackdropVisible,
            },
          )}
          />
        </DomNodePortal>
      </>
    </DigitalDataProvider>
  );
};

LargeScreenNavBar.propTypes = {
  categoryId: PropTypes.string.isRequired,
  storePreview: PropTypes.string.isRequired,
};

export default LargeScreenNavBar;
