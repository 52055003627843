import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useDesignSystemInit from '../../hooks/useDesignSystemInit';
import useMarketingFilters from '../../hooks/useMarketingFilters';
import getEspotSpreads from '../../tools/getEspotSpreads';
import style from '../ProductCard/productCard.module.scss';

export default function EspotCard({
  name,
  content = '',
  timestamp,
  fullCatHead = false,
}) {
  const espotContainer = useRef();
  const [columnSpread, setColumnSpread] = useState({ large: '', small: '' });

  useEffect(() => {
    const thisEspot = espotContainer.current;
    const columnElement = thisEspot.querySelectorAll('[data-column-spread]')[0];
    if (columnElement) {
      const columnElementValue = Number(columnElement.getAttribute('data-column-spread'));
      const spreads = getEspotSpreads({ columnElementValue });
      setColumnSpread(spreads);
    }
  }, [setColumnSpread]);

  useDesignSystemInit(espotContainer);

  useMarketingFilters(espotContainer, name);

  if (!content) {
    return (
      <li
        ref={espotContainer}
        className="genericESpot genericESpot--empty"
        data-espotname={name}
      />
    );
  }

  return (
    (fullCatHead)
      ? (
        <li
          ref={espotContainer}
          className="genericESpot category--header__ems"
          data-espotname={name}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </li>
      )
      : (
        <li
          ref={espotContainer}
          className={`${style.productCard} genericESpot js-aem-content aem-content aem-enabled`}
          data-cachets={timestamp}
          data-espotname={name}
          data-large-column-spread={columnSpread.large}
          data-small-column-spread={columnSpread.small}
        >
          <div
            className={`${style.template} product-template`}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </li>
      )
  );
}

EspotCard.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string,
  fullCatHead: PropTypes.bool,
  timestamp: PropTypes.string,
};
